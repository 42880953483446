<template>
  <div>
    <div v-show="!isSuccess">
      <h6>
        Введите данные для создания учетной записи
      </h6>
      <br />
      <b-row v-for="(item, index) in form" :key="index" align-h="center">
        <b-col>
          <b-form-group
            :label="item.label"
            :label-for="item.id"
            label-cols-sm="4"
            content-cols-sm="8"
            label-align="left"
            label-align-sm="right"
          >
            <BaseTextInput
              :id="item.id"
              v-model="item.value"
              :placeholder="item.placeholder"
              :type="item.type"
              min="6"
              maxlength="16"
              required
            />
            <slot>
              <b-row v-if="isError" class="error">
                <b-col>
                  <span v-if="index !== 2 && language && item.value.match(/[^\dA-Z]/gi)">
                    {{ languageError }}
                  </span>
                  <span v-else-if="item.value.length < 6">
                    {{ item.error }}
                  </span>
                  <span
                    v-else-if="
                      loginEqualPassword && index === 1 && form[index].value === form[--index].value
                    "
                  >
                    {{ passwordError }}
                  </span>
                  <span
                    v-else-if="
                      passConfirm && index === 2 && form[index].value !== form[--index].value
                    "
                  >
                    {{ item.error }}
                  </span>
                </b-col>
              </b-row>
            </slot>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isRegError">
        <b-col>
          <span class="error">
            {{ serverErrorMessage }}
          </span>
        </b-col>
      </b-row>
      <br />
      <b-row align-h="center">
        <BaseButton v-if="registrationInProgress" disabled>
          <template #text>
            <b-spinner small></b-spinner>
            <span class="ml-2">Регистрируем...</span>
          </template>
        </BaseButton>
        <BaseButton v-else @click.stop.prevent="regCustomer" :disabled="isSuccess">
          <template #text>Зарегистрироваться</template>
        </BaseButton>
      </b-row>
    </div>
    <b-row v-show="isSuccess" align-h="center">
      <b-col>
        <h5>Поздравляем!</h5>
        <h5>Вы зарегистрированы</h5>
        <br />
        <BaseButton @click.stop.prevent="goToLoginForm" class="reg-success__btn">
          <template #text>Перейти в личный кабинет</template>
        </BaseButton>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { sendCustomerRequest } from '@/api/common';
import URL from '@/basUrl';

export default {
  data() {
    return {
      languageError: 'Допускаются только буквы латинского алфавита и цифры',
      passwordError: 'Логин и пароль должны отличаться',
      serverErrorMessage: '',
      isError: false,
      isRegError: false,
      isSuccess: false,
      registrationInProgress: false,
      form: [
        {
          id: 'Login',
          label: 'Логин',
          placeholder: 'Введите Логин',
          value: '',
          error: 'Введите логин не менее 6 символов',
          type: 'text'
        },
        {
          id: 'Password',
          label: 'Пароль',
          placeholder: 'Введите Пароль',
          value: '',
          error: 'Введите пароль не менее 6 символов',
          type: 'password'
        },
        {
          id: 'PasswordConfirm',
          label: 'Подтвердите пароль',
          placeholder: 'Повторно введите пароль',
          value: '',
          error: 'Пароли не совпадают',
          type: 'password'
        }
      ]
    };
  },

  components: {
    BaseButton,
    BaseTextInput
  },

  computed: {
    ...mapGetters({
      regData: 'registration/regData',
      firstPage: 'registration/firstPage',
      secondPage: 'registration/secondPage',
      thirdPage: 'registration/thirdPage'
    }),

    language() {
      const check = () => {
        const arr = this.form.map((el) => el.value);
        const arrWithBoolean = arr.join('');
        if (arrWithBoolean.match(/[^\dA-Z]/gi)) {
          return true;
        }
        return false;
      };
      return check();
    },

    valueLength() {
      const arr = this.form.map((el) => el.value);
      const arrayBooleans = arr.map((item) => item.length < 6);
      return arrayBooleans.includes(true);
    },

    loginEqualPassword() {
      const check = () => {
        if (this.form[0].value === this.form[1].value) {
          return true;
        }
        return false;
      };
      return check();
    },

    passConfirm() {
      const check = () => {
        if (this.form[1].value !== this.form[2].value) {
          return true;
        }
        return false;
      };
      return check();
    }
  },

  methods: {
    ...mapActions({
      setNewLogin: 'registration/setNewLogin'
    }),

    ...mapMutations({
      firstStep: 'registration/BASE_FROM'
    }),

    async goToLoginForm() {
      await this.$router.push('/Login');
      this.firstStep(true);
    },

    async regCustomer() {
      if (this.language || this.valueLength || this.loginEqualPassword || this.passConfirm) {
        if (!this.isError) this.isError = true;
      } else {
        await this.setNewLogin(this.form);

        const raw = JSON.stringify({ ...this.regData });

        this.registrationInProgress = true;

        await sendCustomerRequest(URL.REGISTRATION, raw)
          .then((response) => {
            if (response.status === 200) {
              this.isSuccess = true;
            } else {
              this.serverErrorMessage = response.text.replace(/[^\sА-Я]/gi, '');
              this.isRegError = true;
            }
          })
          .catch((e) => console.error(e));

        this.registrationInProgress = false;
      }
    }
  }
};
</script>

<style scoped>
.reg-success__btn {
  margin: 0 auto;
}
</style>
