<template>
  <b-form-input
    :value="value"
    class="input_color"
    v-bind="$attrs"
    :required="required"
    @input="updateValue"
    trim
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number],
      require: false
    },
    required: {
      type: Boolean,
      require: false,
      default: false
    }
  },

  methods: {
    updateValue(event) {
      this.$emit('input', event);
    }
  }
};
</script>

<style></style>
